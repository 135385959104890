/* eslint-disable vue/one-component-per-file */

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// vendor
import { createApp, h } from 'vue'
import wrapper from 'vue3-webcomponent-wrapper'
// vue2Dropzone is using global Vue 2 version
import vue2Dropzone from 'vue2-dropzone/dist/vue2Dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import initTextMessaging from '../text_messaging'
import patches from '../patches.js'
import HelloVue from '../components/hello-vue/hello-vue' // example vue component
import Select2Matter from '../components/select2-matter/select2-matter'
import Select2Client from '../components/select2-client/select2-client'
import Select2User from '../components/select2-user/select2-user'
import OverdueBalanceBar from '../components/overdue-balance-bar/overdue-balance-bar'
import EventColorPicker from '../components/event-color-picker/event-color-picker'

import SmartDocumentRequests from '../components/document-extraction-iq/smart-document-requests'
import ContactSmartDocumentRequests from '../components/document-extraction-iq/contact-smart-document-requests'
import TextEditIq from '../components/text-edit-iq/text-edit-iq'

import ShareInvoicesModal from '../components/share-invoices-modal/share-invoices-modal'
import CustomTabs from '../components/custom-tabs/custom-tabs'
import eSignatureStore from '../e_signature/store'
import ESignatureBuilder from '../e_signature/signature_document_builder/ESignature'
import ESignatureViewer from '../e_signature/signature_document_viewer/ESignature'
import ESignaturePreviewer from '../e_signature/signature_document_previewer/ESignature'
import SignersSetupModal from '../e_signature/SignersSetupModal'
import whiteLabels from '../whitelabel_javascripts/index.js'
import LegacyCustomTabs from '../components/legacy-custom-tabs/legacy-custom-tabs'
import Mandatory2FAMessage from '../mandatory_2fa_message/index.js'
import BrowserIDManager from '../browser_id_manager/index.js'

import '../views/reminder_settings/reminder_form'
import '../views/smart_forms/edit_assembly'
import '../views/smart_forms/edit_invite'
import '../views/matters/matters_index'
import '../custom_reports_filter/initializer.js'
import mixpanel from 'mixpanel-browser'
import lodashDeep from 'lodash-deep'
_.mixin(lodashDeep)

Vue.component('vueDropzone', vue2Dropzone)

const CustomElHelloVue = wrapper(HelloVue, createApp, h)
window.customElements.define('hello-vue', CustomElHelloVue)

const CustomElShareInvoicesModal = wrapper(ShareInvoicesModal, createApp, h)
window.customElements.define('share-invoices-modal', CustomElShareInvoicesModal)

const CustomTabsWrapper = wrapper(CustomTabs, createApp, h)
window.customElements.define('custom-tabs', CustomTabsWrapper)

const Select2MatterWrapper = wrapper(Select2Matter, createApp, h)
window.customElements.define('select2-matter', Select2MatterWrapper)

const Select2UserWrapper = wrapper(Select2User, createApp, h)
window.customElements.define('select2-user', Select2UserWrapper)

const Select2ClientWrapper = wrapper(Select2Client, createApp, h)
window.customElements.define('select2-client', Select2ClientWrapper)

const OverdueBalanceBarWrapper = wrapper(OverdueBalanceBar, createApp, h)
window.customElements.define('overdue-balance-bar', OverdueBalanceBarWrapper)

const EventColorPickerWrapper = wrapper(EventColorPicker, createApp, h)
window.customElements.define('event-color-picker', EventColorPickerWrapper)

const SmartDocumentRequestsWrapper = wrapper(SmartDocumentRequests, createApp, h)
window.customElements.define('smart-document-requests', SmartDocumentRequestsWrapper)

const ContactSmartDocumentRequestsWrapper = wrapper(ContactSmartDocumentRequests, createApp, h)
window.customElements.define('contact-smart-document-requests', ContactSmartDocumentRequestsWrapper)

const CustomTextEditIq = wrapper(TextEditIq, createApp, h)
window.customElements.define('text-edit-iq', CustomTextEditIq)

window.mixpanel = mixpanel

window.LegacyCustomTabs = LegacyCustomTabs

// customElements.define('ajax-input', defineCustomElement(AjaxInput))

// the window load gives time to set/fetch data with the store function
$(window).load(function () {
  const pusherKey = window.store.fetch('textMessagingPusherKey')
  const firmId = window.store.fetch('textMessagingFirmId')
  const textMessagingEnabled = window.store.fetch('textMessagingEnabled')
  const pusherTimeoutFetch = window.store.fetch('textMessagingPusherTimeoutFetch')
  const currentWhiteLabelName = window.store.fetch('currentWhiteLabelName')
  const textMessagingTimeZone = window.store.fetch('textMessagingTimeZone')

  whiteLabels.initWhitelabel({ whiteLabelBrand: currentWhiteLabelName })
  new Mandatory2FAMessage()
  new BrowserIDManager()

  initTextMessaging({ pusherKey, firmId, textMessagingEnabled, pusherTimeoutFetch, textMessagingTimeZone })
  patches.tableOptionsDropdownFix({ toggles: '.dropdown-inside-table-fix' })

  // side effect handling for rails remote js code
  $(document).ajaxSuccess(function () {
    patches.tableOptionsDropdownCleanup()
    patches.tableOptionsDropdownFix({ toggles: '.dropdown-inside-table-fix' })
  })

  // E-Signature
  function initESignatureBuilder (currentSignatureDocument) {
    createApp(ESignatureBuilder, { signatureDocument: currentSignatureDocument })
      .use(eSignatureStore)
      .mount('#esign-box')
  }

  function initESignatureViewer (currentSignatureDocument) {
    createApp(ESignatureViewer, { signatureDocument: currentSignatureDocument })
      .use(eSignatureStore)
      .mount('#esign-viewer-box')
  }

  function initESignaturePreviewer (currentSignatureDocument) {
    createApp(ESignaturePreviewer, { signatureDocument: currentSignatureDocument })
      .use(eSignatureStore)
      .mount('#esign-previewer-box')
  }

  function initSignersSetupModal (signatureDocument) {
    createApp(SignersSetupModal, { signatureDocument })
      .use(eSignatureStore)
      .mount('#signers-setup-box')
  }

  window.initESignatureBuilder = initESignatureBuilder
  window.initESignatureViewer = initESignatureViewer
  window.initESignaturePreviewer = initESignaturePreviewer
  window.initSignersSetupModal = initSignersSetupModal

  let pathname = window.location.pathname

  const findPathByReportType = (term) => {
    if (pathname.includes(term)) {
      return pathname
    }
  }

  function openMenu (linkEl, customRreport = false) {
    linkEl.parent().closest('.expandable').children().eq(1).slideToggle(0)
    linkEl.parent().closest('.expandable').children().eq(2).slideToggle(0)
    linkEl.parent().closest('.expandable').children().eq(3).slideToggle(0)
    linkEl.addClass('opened')
    if (customRreport !== true) {
      linkEl.parent().parent().parent().parent().find('.expandable__title').addClass('opened')
    } else {
      linkEl.parent().parent().parent().parent().parent().find('.expandable__title').addClass('opened')
    }
  }

  function expandSideMenuOnFullHtmlRequest () {
    let linkEl
    let id

    const searchParams = window.location.search
    pathname = pathname + searchParams

    if (pathname.includes('custom_reports')) {
      id = pathname.split('/custom_reports/')[1].split('?')[0]
      linkEl = $(`*[data-report-id="${id}"]`)
      linkEl.addClass('current-report')
      openMenu(linkEl, true)
    }

    switch (pathname) {
      case findPathByReportType('contacts_over_time'):
        linkEl = $('a.ajax-custom-report-link[href*="contacts_over_time"]')
        $('#contacts-over-time-reports-link').addClass('current-report')
        openMenu(linkEl)
        break
      case '/reports/vmax_report?date_range=999':
        linkEl = $('a.ajax-custom-report-link[href*="vmax_report"]')
        $('#vmax-report-reports-link').addClass('current-report')
        openMenu(linkEl)
        break
      case findPathByReportType('expiration_report'):
        linkEl = $('a.ajax-custom-report-link[href*="expiration_report"]')
        $('#expiry-dates-report-link').addClass('current-report')
        openMenu(linkEl)
        break
      case findPathByReportType('form_invitations'):
        linkEl = $('a.ajax-custom-report-link[href*="form_invitations"]')
        $('#form-invitations-report-link').addClass('current-report')
        openMenu(linkEl)
        break
      case findPathByReportType('firm_leads_over_time'):
        linkEl = $('#leads-over-time-link')
        linkEl.addClass('current-report')
        openMenu(linkEl)
        break
      case findPathByReportType('firm_leads_conversions_over_time'):
        linkEl = $('#lead-conversions-over-time-link')
        linkEl.addClass('current-report')
        openMenu(linkEl)
        break
      case findPathByReportType('firm_leads_by_lead_status'):
        linkEl = $('#leads-by-status-link')
        linkEl.addClass('current-report')
        openMenu(linkEl)
        break
      case findPathByReportType('matters_over_time'):
        linkEl = $('#matters-over-time-link')
        linkEl.addClass('current-report')
        openMenu(linkEl)
        break
      case findPathByReportType('matters_by_matter_type'):
        linkEl = $('#matters-by-type-link')
        linkEl.addClass('current-report')
        openMenu(linkEl)
        break
      case findPathByReportType('matters_by_matter_status'):
        linkEl = $('#matters-by-status-link')
        linkEl.addClass('current-report')
        openMenu(linkEl)
        break
      case findPathByReportType('matters_by_preference_category'):
        linkEl = $('#matters-by-preference-category-link')
        linkEl.addClass('current-report')
        openMenu(linkEl)
        break
      case '/reports/invoices_over_time':
        linkEl = $('#invoices-over-time-link')
        linkEl.addClass('current-report')
        openMenu(linkEl)
        break
      case '/reports/invoices_over_time_payment_plan':
        linkEl = $('#invoices-over-time-payment-plan-link')
        linkEl.addClass('current-report')
        openMenu(linkEl)
        break
      case '/reports/invoices_by_client':
        linkEl = $('#invoices-by-client-link')
        linkEl.addClass('current-report')
        openMenu(linkEl)
        break
      case '/reports/invoices_by_client?timing=late':
        linkEl = $('#due-invoices-by-client-link')
        linkEl.addClass('current-report')
        openMenu(linkEl)
        break
      case '/reports/invoices_by_matter':
        linkEl = $('#invoices-by-matter-link')
        linkEl.addClass('current-report')
        openMenu(linkEl)
        break
      case '/reports/invoices_by_matter?timing=late':
        linkEl = $('#due-invoices-by-matter-link')
        linkEl.addClass('current-report')
        openMenu(linkEl)
        break
      case findPathByReportType('charges_over_time'):
        linkEl = $('#invoice-charges-over-time-link')
        linkEl.addClass('current-report')
        openMenu(linkEl)
        break
      case '/reports/invoice_payments_over_time':
        linkEl = $('#payments-over-time-link')
        linkEl.addClass('current-report')
        openMenu(linkEl)
        break
      case '/reports/invoice_payments_by_client':
        linkEl = $('#payments-by-client-link')
        linkEl.addClass('current-report')
        openMenu(linkEl)
        break
      case '/reports/invoice_payments_by_matter':
        linkEl = $('#payments-by-matter-link')
        linkEl.addClass('current-report')
        openMenu(linkEl)
        break
      case '/reports/tasks_by_client':
        linkEl = $('#tasks-by-client-link')
        linkEl.addClass('current-report')
        openMenu(linkEl)
        break
      case '/reports/tasks_by_matter':
        linkEl = $('#tasks-by-matter-link')
        linkEl.addClass('current-report')
        openMenu(linkEl)
        break
      case '/reports/tasks_by_matter?timing=late':
        linkEl = $('#late-tasks-by-matter-link')
        linkEl.addClass('current-report')
        openMenu(linkEl)
        break
      case '/reports/tasks_by_client?timing=late':
        linkEl = $('#late-tasks-by-client-link')
        linkEl.addClass('current-report')
        openMenu(linkEl)
        break
      case findPathByReportType('invoices_by_user'):
        linkEl = $('a.ajax-custom-report-link[href*="invoices_by_user"]')
        linkEl.addClass('current-report')
        openMenu(linkEl)
        break
      case findPathByReportType('invoice_payments_by_user'):
        linkEl = $('a.ajax-custom-report-link[href*="invoice_payments_by_user"]')
        linkEl.addClass('current-report')
        openMenu(linkEl)
        break
      case findPathByReportType('tasks_by_user'):
        linkEl = $('a.ajax-custom-report-link[href*="tasks_by_user"]')
        linkEl.addClass('current-report')
        openMenu(linkEl)
        break
      case findPathByReportType('matters_by_user'):
        linkEl = $('a.ajax-custom-report-link[href*="matters_by_user"]')
        linkEl.addClass('current-report')
        openMenu(linkEl)
        break
    }
  }

  expandSideMenuOnFullHtmlRequest()
})
