const urlBase = '/assistant/editor'

export default {
  async produce (action, text, options = {}) {
    const url = `${urlBase}/produce`
    const body = {
      data: {
        action,
        text,
        ...options
      }
    }

    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    })

    if (!response.ok) {
      const { error } = await response.json()
      console.error(error)
      throw new Error('An error has occurred with Docketwise IQ. Please try again.')
    }

    return response.json()
  }
}
