<script>
import baseModal from './base-modal.vue'
import featureFeedback from '../feature-feedback/feature-feedback'
import multiselect from 'vue-multiselect'
import moment from 'moment'

export default {
  name: 'passport-modal',
  components: {
    baseModal,
    featureFeedback,
    multiselect
  },
  props: ['smartDocumentRequestItem', 'countriesList'],
  emits: ['submit', 'disable-field'],
  data () {
    return {
      smartDocument: this.smartDocumentRequestItem.smart_document,
      countries: JSON.parse(this.countriesList),
      genderOptions: ['Male', 'Female'],
      disabledFields: []
    }
  },
  computed: {
    countryOfBirth: {
      get () {
        return this.countries.find(country => country.id.toString() === this.smartDocument.data.country_of_birth_id.toString())
      },
      set (country) {
        this.smartDocument.data.country_of_birth_id = country.id
      }
    },
    passportCountryOfIssue: {
      get () {
        return this.countries.find(country => country.id.toString() === this.smartDocument.data.passport_or_travel_document_country_id.toString())
      },
      set (country) {
        this.smartDocument.data.passport_or_travel_document_country_id = country.id
      }
    },
    dateOfBirth: {
      get () {
        if (!this.smartDocument.data.date_of_birth) return ''

        const formatedDate = moment(this.smartDocument.data.date_of_birth).format('YYYY-MM-DD')
        return formatedDate
      },
      set (date) {
        if (date) {
          const formatedDate = moment(date).format('MM/DD/YYYY')
          this.smartDocument.data.date_of_birth = formatedDate
        } else {
          this.smartDocument.data.date_of_birth = ''
        }
      }
    },
    passportExpiryDate: {
      get () {
        if (!this.smartDocument.data.passport_or_travel_document_expiry) return ''

        const formatedDate = moment(this.smartDocument.data.passport_or_travel_document_expiry).format('YYYY-MM-DD')
        return formatedDate
      },
      set (date) {
        if (date) {
          const formatedDate = moment(date).format('MM/DD/YYYY')
          this.smartDocument.data.passport_or_travel_document_expiry = formatedDate
        } else {
          this.smartDocument.data.passport_or_travel_document_expiry = ''
        }
      }
    },
    passportIssueDate: {
      get () {
        if (!this.smartDocument.data.passport_or_travel_document_issue_date) return ''

        const formatedDate = moment(this.smartDocument.data.passport_or_travel_document_issue_date).format('YYYY-MM-DD')
        return formatedDate
      },
      set (date) {
        if (date) {
          const formatedDate = moment(date).format('MM/DD/YYYY')
          this.smartDocument.data.passport_or_travel_document_issue_date = formatedDate
        } else {
          this.smartDocument.data.passport_or_travel_document_issue_date = ''
        }
      }
    }
  },
  methods: {
    toggleDisableField (fieldName) {
      if (this.disabledFields.includes(fieldName)) {
        this.disabledFields = this.disabledFields.filter(field => field !== fieldName)
      } else {
        this.disabledFields.push(fieldName)
      }
    },
    getFeatureData () {
      return {
        smart_form_id: this.smartDocumentRequestItem.smart_form_id,
        smart_form_name: this.smartDocumentRequestItem.smart_form_name,
        smart_document_type: this.smartDocumentRequestItem.smart_document_type
      }
    }
  }
}
</script>

<template>
  <base-modal
    :modal-id="`smart-document-modal-${smartDocument.id}`"
    title="Review & Import"
  >
    <template #body>
      <div>
        <img
          :src="smartDocument.s3_signed_url"
          alt="Passport"
          width="500"
        >
      </div>

      <div class="form-group">
        <label for="first_name">First Name</label>
        <img
          v-if="disabledFields.includes('first_name')"
          class="smart-document-icon-toggle-enable"
          src="https://res.cloudinary.com/docketwise/image/upload/v1732555783/app-icons/eye-grey-closed_ettc2p.svg"
          @click="toggleDisableField('first_name')"
        >
        <img
          v-else
          class="smart-document-icon-toggle-enable"
          src="https://res.cloudinary.com/docketwise/image/upload/v1732555783/app-icons/eye-blue-open_xj7x39.svg"
          @click="toggleDisableField('first_name')"
        >
        <input
          id="first_name"
          v-model="smartDocument.data.first_name"
          class="form-control"
          type="text"
          :disabled="disabledFields.includes('first_name')"
        >
      </div>

      <div class="form-group">
        <label for="middle_name">Middle Name</label>
        <img
          v-if="disabledFields.includes('middle_name')"
          class="smart-document-icon-toggle-enable"
          src="https://res.cloudinary.com/docketwise/image/upload/v1732555783/app-icons/eye-grey-closed_ettc2p.svg"
          @click="toggleDisableField('middle_name')"
        >
        <img
          v-else
          class="smart-document-icon-toggle-enable"
          src="https://res.cloudinary.com/docketwise/image/upload/v1732555783/app-icons/eye-blue-open_xj7x39.svg"
          @click="toggleDisableField('middle_name')"
        >
        <input
          id="middle_name"
          v-model="smartDocument.data.middle_name"
          class="form-control"
          type="text"
          :disabled="disabledFields.includes('middle_name')"
        >
      </div>

      <div class="form-group">
        <label for="last_name">Last Name</label>
        <img
          v-if="disabledFields.includes('last_name')"
          class="smart-document-icon-toggle-enable"
          src="https://res.cloudinary.com/docketwise/image/upload/v1732555783/app-icons/eye-grey-closed_ettc2p.svg"
          @click="toggleDisableField('last_name')"
        >
        <img
          v-else
          class="smart-document-icon-toggle-enable"
          src="https://res.cloudinary.com/docketwise/image/upload/v1732555783/app-icons/eye-blue-open_xj7x39.svg"
          @click="toggleDisableField('last_name')"
        >
        <input
          id="last_name"
          v-model="smartDocument.data.last_name"
          class="form-control"
          type="text"
          :disabled="disabledFields.includes('last_name')"
        >
      </div>

      <div class="form-group">
        <label for="gender">Gender</label>
        <img
          v-if="disabledFields.includes('gender')"
          class="smart-document-icon-toggle-enable"
          src="https://res.cloudinary.com/docketwise/image/upload/v1732555783/app-icons/eye-grey-closed_ettc2p.svg"
          @click="toggleDisableField('gender')"
        >
        <img
          v-else
          class="smart-document-icon-toggle-enable"
          src="https://res.cloudinary.com/docketwise/image/upload/v1732555783/app-icons/eye-blue-open_xj7x39.svg"
          @click="toggleDisableField('gender')"
        >
        <select
          id="gender"
          v-model="smartDocument.data.gender"
          class="form-control"
          :disabled="disabledFields.includes('gender')"
        >
          <option
            value=""
          >
            Select Gender
          </option>
          <option
            v-for="option in genderOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="date_of_birth">Date of Birth</label>
        <img
          v-if="disabledFields.includes('date_of_birth')"
          class="smart-document-icon-toggle-enable"
          src="https://res.cloudinary.com/docketwise/image/upload/v1732555783/app-icons/eye-grey-closed_ettc2p.svg"
          @click="toggleDisableField('date_of_birth')"
        >
        <img
          v-else
          class="smart-document-icon-toggle-enable"
          src="https://res.cloudinary.com/docketwise/image/upload/v1732555783/app-icons/eye-blue-open_xj7x39.svg"
          @click="toggleDisableField('date_of_birth')"
        >
        <input
          id="date_of_birth"
          v-model.lazy="dateOfBirth"
          class="form-control"
          type="date"
          :disabled="disabledFields.includes('date_of_birth')"
        >
      </div>

      <div class="form-group">
        <label for="country_of_birth_id">Country of Birth</label>
        <img
          v-if="disabledFields.includes('country_of_birth_id')"
          class="smart-document-icon-toggle-enable"
          src="https://res.cloudinary.com/docketwise/image/upload/v1732555783/app-icons/eye-grey-closed_ettc2p.svg"
          @click="toggleDisableField('country_of_birth_id')"
        >
        <img
          v-else
          class="smart-document-icon-toggle-enable"
          src="https://res.cloudinary.com/docketwise/image/upload/v1732555783/app-icons/eye-blue-open_xj7x39.svg"
          @click="toggleDisableField('country_of_birth_id')"
        >
        <multiselect
          v-model="countryOfBirth"
          name="country_of_birth_id"
          label="name"
          track-by="id"
          :options="countries"
          placeholder="Select country"
          open-direction="bottom"
          :disabled="disabledFields.includes('country_of_birth_id')"
        />
      </div>

      <div class="form-group">
        <label for="state_of_birth">State of Birth</label>
        <img
          v-if="disabledFields.includes('state_of_birth')"
          class="smart-document-icon-toggle-enable"
          src="https://res.cloudinary.com/docketwise/image/upload/v1732555783/app-icons/eye-grey-closed_ettc2p.svg"
          @click="toggleDisableField('state_of_birth')"
        >
        <img
          v-else
          class="smart-document-icon-toggle-enable"
          src="https://res.cloudinary.com/docketwise/image/upload/v1732555783/app-icons/eye-blue-open_xj7x39.svg"
          @click="toggleDisableField('state_of_birth')"
        >
        <input
          id="state_of_birth"
          v-model="smartDocument.data.state_of_birth"
          class="form-control"
          type="text"
          :disabled="disabledFields.includes('state_of_birth')"
        >
      </div>

      <div class="form-group">
        <label for="passport_number">Passport Number</label>
        <img
          v-if="disabledFields.includes('passport_number')"
          class="smart-document-icon-toggle-enable"
          src="https://res.cloudinary.com/docketwise/image/upload/v1732555783/app-icons/eye-grey-closed_ettc2p.svg"
          @click="toggleDisableField('passport_number')"
        >
        <img
          v-else
          class="smart-document-icon-toggle-enable"
          src="https://res.cloudinary.com/docketwise/image/upload/v1732555783/app-icons/eye-blue-open_xj7x39.svg"
          @click="toggleDisableField('passport_number')"
        >
        <input
          id="passport_number"
          v-model="smartDocument.data.passport_number"
          class="form-control"
          type="text"
          maxlength="9"
          :disabled="disabledFields.includes('passport_number')"
        >
      </div>

      <div class="form-group">
        <label for="passport_or_travel_document_expiry">Passport Expiry Date</label>
        <img
          v-if="disabledFields.includes('passport_or_travel_document_expiry')"
          class="smart-document-icon-toggle-enable"
          src="https://res.cloudinary.com/docketwise/image/upload/v1732555783/app-icons/eye-grey-closed_ettc2p.svg"
          @click="toggleDisableField('passport_or_travel_document_expiry')"
        >
        <img
          v-else
          class="smart-document-icon-toggle-enable"
          src="https://res.cloudinary.com/docketwise/image/upload/v1732555783/app-icons/eye-blue-open_xj7x39.svg"
          @click="toggleDisableField('passport_or_travel_document_expiry')"
        >
        <input
          id="passport_or_travel_document_expiry"
          v-model.lazy="passportExpiryDate"
          class="form-control"
          type="date"
          :disabled="disabledFields.includes('passport_or_travel_document_expiry')"
        >
      </div>

      <div class="form-group">
        <label for="passport_or_travel_document_issue_date">Passport Issue Date</label>
        <img
          v-if="disabledFields.includes('passport_or_travel_document_issue_date')"
          class="smart-document-icon-toggle-enable"
          src="https://res.cloudinary.com/docketwise/image/upload/v1732555783/app-icons/eye-grey-closed_ettc2p.svg"
          @click="toggleDisableField('passport_or_travel_document_issue_date')"
        >
        <img
          v-else
          class="smart-document-icon-toggle-enable"
          src="https://res.cloudinary.com/docketwise/image/upload/v1732555783/app-icons/eye-blue-open_xj7x39.svg"
          @click="toggleDisableField('passport_or_travel_document_issue_date')"
        >
        <input
          id="passport_or_travel_document_issue_date"
          v-model.lazy="passportIssueDate"
          class="form-control"
          type="date"
          :disabled="disabledFields.includes('passport_or_travel_document_issue_date')"
        >
      </div>

      <div class="form-group">
        <label for="passport_or_travel_document_country_id">Passport Country of Issue</label>
        <img
          v-if="disabledFields.includes('passport_or_travel_document_country_id')"
          class="smart-document-icon-toggle-enable"
          src="https://res.cloudinary.com/docketwise/image/upload/v1732555783/app-icons/eye-grey-closed_ettc2p.svg"
          @click="toggleDisableField('passport_or_travel_document_country_id')"
        >
        <img
          v-else
          class="smart-document-icon-toggle-enable"
          src="https://res.cloudinary.com/docketwise/image/upload/v1732555783/app-icons/eye-blue-open_xj7x39.svg"
          @click="toggleDisableField('passport_or_travel_document_country_id')"
        >
        <multiselect
          v-model="passportCountryOfIssue"
          name="passport_or_travel_document_country_id"
          label="name"
          track-by="id"
          :options="countries"
          placeholder="Select country"
          open-direction="bottom"
          :disabled="disabledFields.includes('passport_or_travel_document_country_id')"
        />
      </div>

      <feature-feedback
        feature-name="document-extraction-iq"
        :feature-data="getFeatureData()"
      />
    </template>

    <template #footer>
      <button
        class="btn btn-green full-width"
        @click="$emit('submit', smartDocument.data, disabledFields)"
      >
        CONFIRM & IMPORT
      </button>
    </template>
  </base-modal>
</template>
