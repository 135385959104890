<script>
import { computePosition, autoPlacement, shift, offset } from '@floating-ui/dom'
import './text-edit-iq.scss'
import actions from './actions.js'
import IqApi from './iq-api.js'
import MenuItem from './menu-item.vue'
import iqPolicy from '../iq-policy/iq-policy'
import { useStream } from './use-stream.js'
import { capitalize } from 'lodash'

export default {
  name: 'text-edit-iq',
  components: { MenuItem, iqPolicy },
  props: {
    location: {
      type: String,
      default: ''
    },
    textEditClass: {
      type: String,
      default: ''
    },
    textEditStyle: {
      type: String,
      default: ''
    },
    textEditName: {
      type: String,
      default: ''
    },
    textEditInitialValue: {
      type: String,
      default: ''
    },
    textEditType: {
      validator (value) {
        const validTypes = ['textarea', 'input', 'trix']
        return validTypes.includes(value)
      },
      default: 'textarea'
    },
    iqPolicyAccepted: {
      type: Boolean,
      default: false
    },
    trixId: {
      type: String,
      default: ''
    }
  },
  emits: ['keyup', 'keyup-enter', 'blur', 'update:text-edit-value'],
  data () {
    return {
      isMenuVisible: false,
      items: Object.values(actions),
      textEditValue: this.textEditInitialValue,
      isIqLoading: false,
      isIqResponseVisible: false,
      iqResponseText: '',
      iqErrorMessage: null,
      selectedAction: null,
      selectedOption: null
    }
  },
  computed: {
    isTypeTextarea () {
      return this.textEditType === 'textarea'
    },

    isTypeInput () {
      return this.textEditType === 'input'
    },

    isTrix () {
      return this.textEditType === 'trix'
    },

    hasTextToProcess () {
      return this.textEditValue !== ''
    },

    selectedActionLabel () {
      if (!this.selectedAction) return null

      let label = this.selectedActionText
      if (this.selectedOption) label += ` - ${capitalize(this.selectedOptionText)}`

      return label
    },

    selectedActionIconSrc () {
      if (!this.selectedAction) return null

      return actions[this.selectedAction].iconSrc
    },

    selectedActionText () {
      if (!this.selectedAction) return null

      return actions[this.selectedAction].text
    },

    selectedOptionText () {
      if (!this.selectedOption) return null

      if (this.selectedAction !== 'translate') return this.selectedOption

      const languageOption = actions.translate.children.find((child) => child.option === this.selectedOption)
      return languageOption.text
    }
  },
  watch: {
    textEditInitialValue () {
      this.textEditValue = this.textEditInitialValue
    }
  },
  mounted () {
    this.addListenerToCloseMenu()
    if (!this.iqPolicyAccepted) {
      this.addListenerToUpdateIqPolicyTooltipPosition()
    }
    if (this.isTrix) {
      this.addListenerToUpdateTrix()
      $(document).ready(() => {
        this.addListenerForTrixInput()
        this.setTextEditValue(this.trixEditorElement().editor.getDocument().toString())
      })
    }
  },
  methods: {
    handleBlur () {
      this.$emit('blur')
    },

    handleKeyupEnter () {
      this.$emit('keyup-enter')
    },

    handleKeyup () {
      this.$emit('keyup')
    },

    trixEditorElement () {
      return document.querySelector(`#${this.trixId}`)
    },

    async copyIqResponseToClipboard () {
      await navigator.clipboard.writeText(this.iqResponseText)
    },

    insertIqResponse () {
      this.setElementContent(this.iqResponseText)
      this.$refs.iqElement.focus()
      this.resetTextEditIq()
    },

    resetTextEditIq () {
      this.isMenuVisible = false
      this.textEditValue = this.getElementContent()
      this.isIqLoading = false
      this.isIqResponseVisible = false
      this.iqResponseText = ''
      this.iqErrorMessage = null
      this.selectedAction = null
      this.selectedOption = null
    },

    async retrySelectedAction () {
      const action = this.selectedAction
      const option = this.selectedOption
      this.resetTextEditIq()
      await this.handleIqAction(action, option)
    },

    setSelectedAction (action, option) {
      this.selectedAction = action
      this.selectedOption = option
    },

    setTextEditValue (text) {
      this.textEditValue = text
      this.$emit('update:text-edit-value', text)
    },

    handleInput (event) {
      const inputText = event.target.value
      this.setTextEditValue(inputText)
    },

    handleError (error) {
      this.isIqLoading = false
      this.iqErrorMessage = error
      console.error(`[TextEditIq] ${error}`)
    },

    handleStreamResponse (text, isComplete, error) {
      if (!error) {
        this.isIqLoading = false
        this.isIqResponseVisible = true
        this.iqResponseText = text
      } else {
        this.handleError(error)
      }
    },

    async handleIqAction (action, option) {
      try {
        this.resetTextEditIq()
        this.hideMenu()
        this.isIqLoading = true
        this.setSelectedAction(action, option)
        if (this.isTrix) this.updateTrixPosition()

        const text = this.textEditValue
        if (text.trim() === '') throw new Error('Text is empty. A text is required to process the action.')

        const options = { location: this.location }
        if (action === 'translate') options.lang = option
        if (action === 'change_tone') options.tone = option

        const { channel, token } = await IqApi.produce(action, text, options)

        useStream(channel, token, this.handleStreamResponse)
      } catch (error) {
        this.handleError(error)
      }
    },

    addListenerToCloseMenu () {
      const _this = this
      document.addEventListener('click', function (event) {
        const { target } = event
        const { button, menu } = _this.$refs
        if (!target.isEqualNode(button) && !target.isEqualNode(menu)) {
          _this.hideMenu()
        }
      })
    },

    addListenerToUpdateIqPolicyTooltipPosition () {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0
      }
      const callback = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) this.updateIqPolicyTooltipPosition()
        })
      }
      const observer = new IntersectionObserver(callback, options)
      observer.observe(this.$refs.button)
    },

    addListenerForTrixInput () {
      const _this = this
      _this.trixEditorElement().addEventListener('trix-change', function (event) {
        const text = _this.trixEditorElement().editor.getDocument().toString()
        _this.setTextEditValue(text)
      })
    },

    addListenerToUpdateTrix () {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0
      }
      const callback = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) this.updateTrixPosition()
        })
      }
      const observer = new IntersectionObserver(callback, options)
      observer.observe(this.$refs.button)
    },

    async updateMenuPosition () {
      const { x, y } = await computePosition(this.$refs.button, this.$refs.menu, {
        placement: 'bottom',
        middleware: [offset(6), autoPlacement(), shift()]
      })
      this.$refs.menu.style.left = `${x}px`
      this.$refs.menu.style.top = `${y}px`
    },

    async updateIqPolicyTooltipPosition () {
      if (this.$refs.button && this.$refs.iqPolicyTooltip) {
        const { x, y } = await computePosition(this.$refs.button, this.$refs.iqPolicyTooltip, {
          placement: 'left',
          middleware: [offset(6), autoPlacement(), shift()]
        })

        this.$refs.iqPolicyTooltip.style.left = `${x}px`
        this.$refs.iqPolicyTooltip.style.top = `${y}px`
      }
    },

    async updateTrixPosition () {
      if (this.trixEditorElement() && this.$refs.button) {
        const { x, y } = await computePosition(this.trixEditorElement(), this.$refs.button, {
          placement: 'bottom-end',
          middleware: [offset(({ rects }) => ({
            alignmentAxis: (rects.floating.width / 3),
            mainAxis: -rects.floating.height - 5
          }))]
        })

        this.$refs.button.style.left = `${x}px`
        this.$refs.button.style.top = `${y}px`
      }
    },

    async showMenu () {
      this.isMenuVisible = true
      this.$refs.menu.style.display = 'block'
      await this.updateMenuPosition()
    },

    async toggleMenu () {
      if (!this.hasTextToProcess) return

      if (this.isMenuVisible) {
        this.hideMenu()
      } else {
        await this.showMenu()
      }
    },

    hideMenu () {
      if (this.$refs.menu) {
        this.isMenuVisible = false
        this.$refs.menu.style.display = ''
        this.$refs.menuItems.forEach((menuItem) => menuItem.hideSubmenu())
      }
    },

    getElementContent () {
      if (this.isTrix) {
        return this.trixEditorElement().editor.getDocument().toString()
      } else {
        return this.$refs.iqElement.value
      }
    },

    setElementContent (text) {
      if (this.isTrix) {
        const editor = this.trixEditorElement().editor
        editor.loadHTML('')
        editor.setSelectedRange([0, 1])
        editor.insertString(text)
      } else {
        const { iqElement } = this.$refs
        if (iqElement) {
          iqElement.value = text
          iqElement.dispatchEvent(new Event('input'))
        }
      }
    }
  }
}
</script>

<template>
  <div class="text-edit-iq-component">
    <textarea
      v-if="isTypeTextarea || isTrix"
      v-show="isTypeTextarea"
      ref="iqElement"
      :style="textEditStyle"
      :class="textEditClass"
      :value="textEditValue"
      :name="textEditName"
      v-bind="$attrs"
      @input="handleInput"
      @blur="handleBlur"
      @keyup.prevent.enter="handleKeyupEnter"
      @keyup.prevent="handleKeyup"
    />
    <input
      v-if="isTypeInput"
      ref="iqElement"
      :style="textEditStyle"
      :class="textEditClass"
      :value="textEditValue"
      :name="textEditName"
      v-bind="$attrs"
      @input="handleInput"
      @blur="handleBlur"
      @keyup.prevent.enter="handleKeyupEnter"
      @keyup.prevent="handleKeyup"
    >
    <button
      id="iq-button"
      ref="button"
      class="iq-menu-button"
      :class="{ inactive: !hasTextToProcess }"
      @click.prevent="toggleMenu"
    />
    <ul
      id="iq-menu"
      ref="menu"
      class="iq-menu"
    >
      <MenuItem
        v-for="(item, index) in items"
        ref="menuItems"
        :key="index"
        :icon-src="item.iconSrc"
        :text="item.text"
        :children="item.children"
        :action="item.action"
        @iq-action="handleIqAction"
      />
    </ul>
    <div class="iq-policy-tooltip" v-if="!iqPolicyAccepted" ref="iqPolicyTooltip">
      <iqPolicy
        feature-title="Docketwise IQ Text Editing"
        feature-description="Improve, summarize, and translate with generative AI assistance in Docketwise."
        :accepted="iqPolicyAccepted"
      />
    </div>
  </div>

  <Transition>
    <div class="dw-txt-edit-response-container" v-show="(isIqLoading || isIqResponseVisible || iqErrorMessage)">
      <div class="dw-txt-edit-response-top-container">
        <div class="dw-txt-edit-response-top-left">
          <div class="dw-txt-edit-response-selected-opt">
            <img :src="selectedActionIconSrc">
            {{ selectedActionLabel }}
          </div>
        </div>
        <div class="dw-txt-edit-response-top-right" v-show="isIqResponseVisible">
          <div class="dw-txt-edit-response-top-action-icon" data-toggle="tooltip" data-placement="top" title="Copy to clipboard" @click="copyIqResponseToClipboard">
            <img src="https://res.cloudinary.com/docketwise/image/upload/v1733152213/text-edit-iq-icons/copy-clipboard-icon-wppnn.svg">
          </div>
          <div class="dw-txt-edit-response-top-action-icon" data-toggle="tooltip" data-placement="top" title="Regenerate" @click="retrySelectedAction">
            <img src="https://res.cloudinary.com/docketwise/image/upload/v1733152217/text-edit-iq-icons/regenerate-icon-u0hbh.svg">
          </div>
        </div>
      </div>
      <div v-show="isIqLoading" class="dw-txt-edit-response-loading-container">
        <img src="https://res.cloudinary.com/docketwise/image/upload/v1733152217/text-edit-iq-icons/regenerate-icon-u0hbh.svg" class="dw-txt-edit-rotating-icon" />
        Docketwise IQ is working on it...
      </div>
      <div v-show="iqErrorMessage" class="dw-txt-edit-response-error-container">
        <img src="https://res.cloudinary.com/docketwise/image/upload/v1733152213/text-edit-iq-icons/error-exclamation-icon-jxkfr.svg" class="dw-txt-edit-error-icon" />
        {{ iqErrorMessage }}
      </div>
      <Transition>
        <div class="dw-txt-edit-response-middle-container" v-show="isIqResponseVisible">
          {{ iqResponseText }}
        </div>
      </Transition>
      <Transition>
        <div class="dw-txt-edit-response-bottom-container" v-show="isIqResponseVisible">
          <a class="dw-txt-edit-silent-button" @click="resetTextEditIq">Discard</a>
          <a class="dw-txt-edit-filled-button" @click="insertIqResponse">Insert</a>
        </div>
      </Transition>
      <Transition>
        <div class="dw-txt-edit-response-bottom-container" v-show="iqErrorMessage">
          <a class="dw-txt-edit-silent-button" @click="resetTextEditIq">Close</a>
          <a class="dw-txt-edit-filled-button" @click="retrySelectedAction">Retry</a>
        </div>
      </Transition>
      <Transition>
        <div class="dw-txt-edit-response-disclaimer-container" v-show="isIqResponseVisible">
          <img src="https://res.cloudinary.com/docketwise/image/upload/v1733152215/text-edit-iq-icons/disclaimer-icon-ksgsh.svg">
          <a href="https://supportcenter.docketwise.com/en/articles/10165612-docketwise-iq" target="_blank">Learn more</a> about Docketwise IQ. Please use your best professional judgment and review the output prior to using it.
        </div>
      </Transition>
    </div>
  </Transition>
</template>
