<script>
import { computePosition, autoPlacement, shift, offset } from '@floating-ui/dom'
export default {
  name: 'menu-item',
  props: {
    iconSrc: {
      type: String,
      required: false,
      default: null
    },
    text: {
      type: String,
      required: true
    },
    action: {
      type: String,
      required: false,
      default: null
    },
    option: {
      type: String,
      required: false,
      default: null
    },
    children: {
      type: Array,
      required: false,
      default (rawProps) {
        return []
      }
    }
  },
  emits: ['itemClick', 'iqAction'],
  computed: {
    submenuRef () {
      return this.$refs.submenu
    },
    hasSubItems () {
      return this.children.length > 0
    }
  },
  methods: {
    handleIqAction (action, option = null) {
      this.$emit('iqAction', action, option)
    },

    handleClick () {
      if (this.action) {
        this.$emit('iqAction', this.action, this.option)
      }
      this.$emit('itemClick')
    },

    async updateSubmenuPosition () {
      const { x, y } = await computePosition(this.$refs.item, this.$refs.submenu, {
        placement: 'right',
        middleware: [offset(1), autoPlacement(), shift()]
      })
      this.$refs.submenu.style.left = `${x}px`
      this.$refs.submenu.style.top = `${y}px`
    },
    async showSubmenu () {
      if (this.submenuRef) {
        this.submenuRef.style.display = 'block'
        await this.updateSubmenuPosition()
      }
    },
    hideSubmenu () {
      if (this.submenuRef) {
        this.submenuRef.style.display = ''
      }
    }
  }
}
</script>

<template>
  <li
    ref="item"
    class="menu-item"
    @click="handleClick"
    @mouseover="showSubmenu"
    @mouseout="hideSubmenu"
  >
    <img
      v-if="iconSrc"
      :src="iconSrc"
    >
    <span>{{ text }}</span>
    <ul
      v-if="hasSubItems"
      ref="submenu"
      class="iq-menu submenu"
    >
      <MenuItem
        v-for="(item) in children"
        :key="item"
        :icon-src="item.iconSrc"
        :text="item.text"
        :action="item.action"
        :option="item.option"
        @iq-action="handleIqAction"
      />
    </ul>
  </li>
</template>
