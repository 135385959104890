<script>
import axios from 'axios'
import iqPolicy from '../iq-policy/iq-policy'

export default {
  name: 'smart-document-requests',
  components: {
    iqPolicy
  },
  props: ['smartFormId', 'smartDocumentRequestsList', 'smartFormContactsList', 'iqPolicyAccepted', 'iqFeaturesAllowed'],
  data () {
    return {
      smartDocumentRequests: JSON.parse(this.smartDocumentRequestsList),
      smartFormContacts: JSON.parse(this.smartFormContactsList),
      newSmartDocumentRequest: {
        smart_form_id: this.smartFormId,
        contact_id: null,
        smart_document_type: null
      },
      iqFeaturesAccessAllowed: (this.iqFeaturesAllowed === 'true')
    }
  },
  methods: {
    openNewSmartDocumentRequestModal () {
      if (!this.iqFeaturesAccessAllowed) {
        toastr.warning('You must enable Docketwise IQ to use this feature, which must be enabled by an admin on your account.')
        return
      }
      $('#smart-document-request-modal').modal('show')
    },
    async addSmartDocumentRequest () {
      try {
        const smartDocumentRequestResponse = await axios.post('/smart_document_requests',
          { smart_document_request: this.newSmartDocumentRequest }
        )
        const smartDocumentRequest = smartDocumentRequestResponse.data

        this.smartDocumentRequests.push(smartDocumentRequest)
        this.newSmartDocumentRequest = {
          smart_form_id: this.smartFormId,
          contact_id: null,
          smart_document_type: null
        }
        toastr.success('Smart document request successfully created')
        $('#smart-document-request-modal').modal('hide')
        window.location.reload()
      } catch (err) {
        const errorMessage = err.response.data.message
        toastr.error(errorMessage)
      }
    },
    async deleteSmartDocumentRequest (index) {
      if (!confirm('Are you sure you want to delete this smart document request?')) return (null)
      const smartDocumentRequest = this.smartDocumentRequests[index]

      try {
        await axios.delete(`/smart_document_requests/${smartDocumentRequest.id}`)
        this.smartDocumentRequests.splice(index, 1)
        toastr.success('Smart document request successfully removed')
        window.location.reload()
      } catch {
        toastr.error('Something went wrong')
      }
    }
  }
}
</script>

<template>
  <div id="smart-form-assembly-documents">
    <div
      id="smart-document-request-modal"
      role="dialog"
      class="modal fade"
    >
      <div
        role="document"
        class="modal-dialog"
      >
        <div class="modal-content small-modal">
          <div class="modal-header">
            <button
              data-dismiss="modal"
              type="button"
              class="close"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title">
              Create Document Request
            </h4>
          </div>
          <div class="modal-body user-invite-modal">
            <div class="form-group">
              <label for="smart_document_type">Smart doc type</label>
              <select
                id="smart_document_type"
                v-model="newSmartDocumentRequest.smart_document_type"
                required="required"
                class="form-control"
              >
                <option value="" />
                <option
                  selected="selected"
                  value="passport"
                >
                  Passport
                </option>
                <option
                  value="green_card"
                >
                  Green Card
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="contact_id">Contact / Company</label>
              <select
                id="contact_id"
                v-model="newSmartDocumentRequest.contact_id"
                required="required"
                class="form-control"
              >
                <option value="" />
                <option
                  v-for="smartFormContact in smartFormContacts"
                  :key="smartFormContact.id"
                  :value="smartFormContact.id"
                >
                  {{ smartFormContact.full_name }}
                </option>
              </select>
            </div>
            <iq-policy
              feature-title="Docketwise IQ Document Extraction"
              feature-description="Automatically extract data from IDs and other documents to streamline information collection with generative AI assistance in Docketwise."
              :accepted="iqPolicyAccepted"
            />
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-green full-width"
              @click="addSmartDocumentRequest"
            >
              Create Document Request
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid dw-sf-asseble-main-container">
      <div class="dw-sf-section-top dw-sf-documents-title-container">
        <div class="dw-sf-section-title">
          <span>Documents</span>
        </div>
        <div class="dw-sf-section-subtitle">
          Create your document requests.
        </div>
      </div>
      <div class="container-fluid">
        <div class="dw-sf-invititations-table-title-container">
          <div class="dw-sf-invititations-table-title-left">
            Document Requests
          </div>
          <div class="dw-sf-invititations-table-title-right">
            <button
              class="btn btn-default btn-green btn-invite-contact-top-right dw-sf-documents-create-btn"
              :class="{ disabled: !iqFeaturesAccessAllowed }"
              @click="openNewSmartDocumentRequestModal"
            >
              Create Document Request
            </button>
          </div>
        </div>
        <div
          v-if="smartDocumentRequests.length > 0"
          class="dw-internal-table-container dw-mt-18"
        >
          <table
            id="admin-datatable"
            class="table"
          >
            <thead>
              <tr>
                <th>Contact/Company</th>
                <th>Status</th>
                <th>Document Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(smartDocumentRequest, index) in smartDocumentRequests"
                :key="index"
              >
                <td>{{ smartDocumentRequest.contact_full_name }}</td>
                <td>{{ smartDocumentRequest.status_formatted }}</td>
                <td>{{ `Smart Doc (${smartDocumentRequest.smart_document_type_formatted})` }}</td>
                <td>
                  <a
                    class="red"
                    @click="deleteSmartDocumentRequest(index)"
                  >Remove</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          v-else
          class="dw-sf-invitations-empty-container dw-sf-document-req-empty-state"
        >
          <div class="dw-sf-invitations-empty-img">
            <img src="https://res.cloudinary.com/docketwise/image/upload/v1730822974/app-icons/documents-empty-state-graphic_ydmtsq.svg">
          </div>
          <div class="dw-sf-invitations-empty-title">
            Request key documents from your clients.
          </div>
          <div class="dw-sf-invitations-empty-subtitle">
            Add document requests to gather evidentiary documents and extract key information.
          </div>
          <a @click="openNewSmartDocumentRequestModal">
            <button
              class="btn"
              :class="{ disabled: !iqFeaturesAccessAllowed }"
            >
              Create Document Request
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
