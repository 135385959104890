<script>
import axios from 'axios'

export default {
  name: 'iq-policy',
  props: ['featureTitle', 'featureDescription', 'accepted'],
  data () {
    return {
      acceptedByUser: (this.accepted === 'true')
    }
  },
  methods: {
    async acceptIqPolicy () {
      try {
        await axios.patch('/users/accept_iq_policy')
        this.acceptedByUser = true
        toastr.success("You have acknowledged Docketwise IQ's Privacy Policy and Terms.")
      } catch {
        this.acceptedByUser = false
        toastr.error('Something went wrong')
      }
    }
  }
}
</script>

<template>
  <div
    v-if="!acceptedByUser"
    class="dw-iq-policy"
  >
    <img src="https://res.cloudinary.com/docketwise/image/upload/v1730756835/app-icons/ackow-icon_wyic4p.svg">
    <div class="dw-iq-policy-feature-title">
      {{ featureTitle }}
    </div>
    <div class="dw-iq-policy-feature-description">
      {{ featureDescription }}
    </div>
    <div class="dw-iq-policy-links">
      <a
        href="https://supportcenter.docketwise.com/en/articles/10165612-docketwise-iq"
        target="_blank"
      >
        Learn more
      </a>
      about Docketwise IQ. The Docketwise
      <a
        href="https://www.docketwise.com/privacy-policy"
        target="_blank"
      >
        Privacy Policy
      </a>
      and the Docketwise
      <a
        href="https://www.docketwise.com/terms-conditions"
        target="_blank"
      >
        Terms
      </a>
      apply.
    </div>

    <div class="dw-iq-policy-btn">
      <button
        class="btn btn-green"
        @click.prevent="acceptIqPolicy"
      >
        Understood
      </button>
    </div>
  </div>
</template>

<style scoped>
  .dw-iq-policy {
    padding: 19px 24px 24px 24px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: #FFF;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.08);
  }

  .dw-iq-policy-feature-title {
    color: #333333;
    font-family: "Playfair Display";
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: 26px;
    margin-bottom: 10px;
  }

  .dw-iq-policy-feature-description {
    color: #666666;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .dw-iq-policy-links {
    border-radius: 5px;
    background: #F6F6F6;
    padding: 10px 20px;
    color: #000;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.2px;
    margin-bottom: 20px;
  }

  .dw-iq-policy-btn {
    text-align: right;
  }
</style>
