import { ref } from 'vue'
import { Amplify } from '@aws-amplify/core'
import { generateClient } from 'aws-amplify/api'

function configureAmplify () {
  Amplify.configure({
    API: {
      GraphQL: {
        endpoint: window.affinipayAiStreamUrl,
        region: 'us-east-1',
        defaultAuthMode: 'lambda'
      }
    }
  })
}

export function useStream (channel, token, callback) {
  configureAmplify()

  const text = ref('')
  const isComplete = ref(false)

  console.info(`[TextEditIQ] Subscribing to channel: ${channel}`)

  function validateTimeout () {
    if (text.value === '') {
      handleError(new Error('Docketwise IQ has taken too long to process. Please try again.'))
    }
  }

  function handleError (error) {
    subscription?.unsubscribe()
    console.error(`[TextEditIQ] ${error}`)
    callback(text, isComplete, error)
  }

  const client = generateClient()
  const onSubscribeToChannel = `
  subscription SubscribeToChannel($channel: String!) {
    subscribe(name: $channel) {
      name
      data
    }
  }
  `
  const timeoutId = setTimeout(validateTimeout, 30000)

  const subscription = client.graphql({
    authToken: `Bearer ${token}`,
    authMode: 'lambda',
    query: onSubscribeToChannel,
    variables: { channel }
  }).subscribe({
    next: ({ data }) => {
      try {
        clearTimeout(timeoutId)
        const parsedData = JSON.parse(data.subscribe.data)
        text.value = parsedData?.content
        isComplete.value = parsedData?.complete === true

        callback(text, isComplete)

        if (isComplete.value) {
          subscription?.unsubscribe()
        }
      } catch (e) {
        console.error('[TextEditIQ] Stream response could not be parsed.', data, e)
      }
    },
    error: (error) => {
      clearTimeout(timeoutId)
      const errorMessage = error.errors[0].message
      handleError(errorMessage)
    }
  })

  return { text, isComplete }
}
