<script>
import axios from 'axios'
import greenCardModal from './green-card-modal.vue'
import passportModal from './passport-modal.vue'

export default {
  name: 'smart-document-modal',
  components: {
    greenCardModal,
    passportModal
  },
  props: ['smartDocumentRequest', 'countries'],
  methods: {
    async handleSubmit (userData, disabledFields) {
      try {
        await axios.put(`/smart_document_requests/${this.smartDocumentRequest.id}/smart_documents/${this.smartDocumentRequest.smart_document.id}/import_data`, { user_modified_data: userData, disabled_fields: disabledFields })
        toastr.success('Contact successfully updated')
        $(`#smart-document-modal-${this.smartDocumentRequest.smart_document.id}`).modal('hide')
        window.location.reload()
      } catch (err) {
        const errorMessage = err.response.data.message
        toastr.error(errorMessage)
      }
    }
  }
}
</script>

<template>
  <div>
    <passport-modal
      v-if="smartDocumentRequest.smart_document_type === 'passport'"
      :smart-document-request-item="smartDocumentRequest"
      :countries-list="countries"
      @submit="handleSubmit"
    />
    <green-card-modal
      v-if="smartDocumentRequest.smart_document_type === 'green_card'"
      :smart-document-request-item="smartDocumentRequest"
      :countries-list="countries"
      @submit="handleSubmit"
    />
  </div>
</template>

<style>
  .smart-document-icon-toggle-enable {
    margin-left: 5px;
    cursor: pointer;
  }
</style>
