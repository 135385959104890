export default {
  improve_writing: {
    text: 'Improve Writing',
    iconSrc: 'https://res.cloudinary.com/docketwise/image/upload/v1733152215/text-edit-iq-icons/improve-alt-u4qnp.svg',
    action: 'improve_writing'
  },
  change_tone: {
    text: 'Change Tone',
    iconSrc: 'https://res.cloudinary.com/docketwise/image/upload/v1733152213/text-edit-iq-icons/change-tone-alt-rwnk7.svg',
    children: [
      { text: 'Formal', action: 'change_tone', option: 'formal' },
      { text: 'Assertive', action: 'change_tone', option: 'assertive' },
      { text: 'Persuasive', action: 'change_tone', option: 'persuasive' },
      { text: 'Empathetic', action: 'change_tone', option: 'empathetic' },
      { text: 'Advisory', action: 'change_tone', option: 'advisory' },
      { text: 'Confident', action: 'change_tone', option: 'confident' },
      { text: 'Neutral', action: 'change_tone', option: 'neutral' }
    ]
  },
  translate: {
    text: 'Translate to',
    iconSrc: 'https://res.cloudinary.com/docketwise/image/upload/v1733152218/text-edit-iq-icons/translate-to-alt-ha7gx.svg',
    children: [
      { text: 'English', action: 'translate', option: 'EN' },
      { text: 'Spanish', action: 'translate', option: 'ES' }
    ]
  },
  summarize: {
    text: 'Summarize',
    iconSrc: 'https://res.cloudinary.com/docketwise/image/upload/v1733152217/text-edit-iq-icons/summarize-alt-szfci.svg',
    action: 'summarize'
  }
}
